import React, { Suspense } from "react";
// import Background from "./fiber/Background";
// import Land from "./fiber/Land";
import CubeGroup from "./fiber/Cubes";

function Cubes() {
  return (
    <>
      {/* <Suspense fallback={null}>
        <Background position={[0, 0, -10000]} rotation={[0, 0, Math.PI / 2]} />
      </Suspense> */}
      <Suspense fallback={null}>
        <CubeGroup />
      </Suspense>
      {/* <Land /> */}
    </>
  );
}
export default Cubes;
