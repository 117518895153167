/* eslint-disable no-unused-vars */
import * as dat from 'dat.gui';
import React, { Suspense, useRef, useState, useEffect } from 'react';
import { useThree, useFrame } from "@react-three/fiber";
import { useAspect, useTexture } from '@react-three/drei';
import vertexShader from "./vert.glsl";
import sweetFrag from "./sweet.frag";
import koFrag from "./knockout.frag";
import fragmentShader from "./frag.glsl";

import * as THREE from 'three';
import useStore from "@shared/hooks/useStore";

function VideoPlane({videoUrl, ...props}) {

  // const gui = useRef(null);
  
  const mesh = useRef(null);
  // const isomesh = useRef(null);

  const { pause, loadSong, getNoiseSize, getUTime, player, fft } = useStore.getState();
  // const playing = useStore((state) => state.playing);

  const logoIcon = 'https://d3cob2ifddj5ku.cloudfront.net/cms-images/logo-icon.png';
  // const logoUrl = 'https://d3cob2ifddj5ku.cloudfront.net/cms-images/tattoo-logo_2022-08-06-013259_oypc.png';
  const logoUrl2 = 'https://d3cob2ifddj5ku.cloudfront.net/cms-images/tattoo-logo-2.png';
  const videoRef = useRef(Object.assign(document.createElement('video'), { src: videoUrl, crossOrigin: 'Anonymous', loop: true, muted: true }));
  const vtRef = useRef(new THREE.VideoTexture(videoRef.current));
  const vpRef = useRef(useTexture('https://d3cob2ifddj5ku.cloudfront.net/cms-images/AgencyReelVideoPoster-2020.jpg'));
  const logoRef = useRef(useTexture(logoUrl2));
  const bumperRef = useRef(useTexture(logoIcon));
  const svgTexture = useRef(null);
  const size = useAspect(1280, 720);
  svgTexture.current = useTexture('https://d3cob2ifddj5ku.cloudfront.net/cms-images/tattoo-logo.svg')

  videoRef.current.style.display = "none";
  document.body.appendChild(videoRef.current);

  // useEffect(() => {
  //   if(gui.current) return;

  //   gui.current = new dat.GUI({autoPlace: false, name: 'Options'});
  //   const guiContainer = document.querySelector('#gui-container');
  //   guiContainer.appendChild(gui.current.domElement);

  //   // gui.add(plane.material.uniforms.hoverRadius, "value", 0, 1).name("radius");
  //   gui.current.add(mesh.current.material.uniforms.depth, 'value', 0, 100).name('depth');
  //   gui.current.add(mesh.current.material.uniforms.distortion, 'value', 0, 100).name('distortion'); 
  //   gui.current.add(mesh.current.material.uniforms.distortion2, 'value', 0, 100).name('distortion2');  
  //   gui.current.add(mesh.current.material.uniforms.speed, 'value', 0, 100).name('speed');  
  //   gui.current.add(mesh.current.material.uniforms.rollSpeed, 'value', 0, 100).name('rollSpeed');  
  //   gui.current.add(mesh.current.material.uniforms.uvOffset.value, 'x', -2, 2).name('uvOffset - X');  
  //   gui.current.add(mesh.current.material.uniforms.uvOffset.value, 'y', -2, 2).name('uvOffset - Y');  
  //   gui.current.add(mesh.current.material.uniforms.scale, 'value', 0, 100).name('scale');  
  
  // }, []);

  useEffect(() => {
    pause();
    loadSong({songId: 1}).then((data) => {
      videoRef.current.play();
      // console.log(data);
      // console.log(player);
    });
    // vtRef.current = new THREE.VideoTexture(videoRef.current);

    logoRef.current.wrapS = THREE.RepeatWrapping;
    logoRef.current.wrapT = THREE.RepeatWrapping;
    logoRef.current.repeat.set(2, 2);

    bumperRef.current.wrapS = THREE.ClampToEdgeWrapping;
    bumperRef.current.wrapT = THREE.ClampToEdgeWrapping;

  }, [loadSong, pause]);

  // useEffect(() => {

  //   console.log('playing? ', playing);

  //   if(!playing) {
  //     videoRef.current.pause();
  //   }
  //   else {
  //     videoRef.current.play();
  //   }
  // }, [playing]);
  
  useFrame(() => {
    mesh.current.material.uniforms.uTime.value = getUTime();
    mesh.current.material.uniforms.noiseSize.value = getNoiseSize();
    // mesh.current.material.uniforms.freqs.value = fft.getValue();
    mesh.current.material.needsUpdate = true;
    // console.log(mesh.current.material.uniforms.uTime.value, mesh.current.material.uniforms.noiseSize.value);
  });

  window.mesh = mesh.current;

  return (
    <>
    {/* <directionalLight args={[0xfff0dd, 1]} position={[0, 5, 10]} /> */}
    {/* <mesh
      ref={isomesh}
      name="video-iso"
      scale={size}
      position={[0,0,0,10000]}
      // rotation={[Math.PI, Math.PI, Math.PI]}
      // {...props}
    >
      <icosahedronBufferGeometry />
      <shaderMaterial
        uniforms={{
          uTime: { type: 'f', value: 0.0 },
          uRes: { type: 'vec2', value: { x: 500, y: 500 } },
          uMouse: { type: 'vec3', value: { x: 0, y: 0, z: 0 } },
          noiseSize: { type: 'f', value: 1.0 },
          depth: { type: 'f', value: 10.0 },
          overlay: { type: 't', value: logoRef.current },
          pallete: { type: 't', value: vtRef.current },
          uvOffset : { type : 'v', value : { x: 0.0, y: -0.5 }},
          uvOffset : { type : 'v', value :{ x: 0.15, y: -0.5 } },
          scale:    { type: "f", value: 1.0 },

          distortion:   { type: "f", value: 3.0 },
          distortion2:  { type: "f", value: 2.0 },
          speed:     	{ type: "f", value: 0.2 },
          rollSpeed:    { type: "f", value: 0.2 },
        }}
        vertexShader={vertexShader}
        fragmentShader={fragmentShader}
        depthTest={true}
        transparent={false}
        // wireframe={true}
      />
    </mesh> */}
    <mesh
      ref={mesh}
      name="video-plane"
      scale={size}
      rotation={[Math.PI, Math.PI, Math.PI]}
      {...props}
    >
      <planeBufferGeometry args={[1,1]} />
      <shaderMaterial
        uniforms={{
          uTime: { type: 'f', value: 0.0 },
          uRes: { type: 'vec2', value: { x: 500, y: 500 } },
          uMouse: { type: 'vec3', value: { x: 0, y: 0, z: 0 } },
          noiseSize: { type: 'f', value: 1.0 },
          freqs: {
            type: "fv",
            value: new Array(32).fill(1.0),
          },
          depth: { type: 'f', value: 10.0 },
          overlay: { type: 't', value: logoRef.current },
          pallete: { type: 't', value: vtRef.current },
          bumper: { type: 't', value: bumperRef.current },
          uvOffset : { type : 'v', value :{ x: 0.15, y: -0.5 } },
          scale:    { type: "f", value: 0.8 },

          distortion:   { type: "f", value: 3.0 },
          distortion2:  { type: "f", value: 2.0 },
          speed:     	{ type: "f", value: 0.2 },
          rollSpeed:    { type: "f", value: 0.2 },
        }}
        vertexShader={vertexShader}
        fragmentShader={fragmentShader}
        depthTest={true}
        transparent={false}
        // wireframe={true}
      />
    </mesh>
    </>
  );
}

export default VideoPlane;
