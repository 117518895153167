import React, { useRef } from "react";
import { useFrame } from "@react-three/fiber";
import useStore from "@shared/hooks/useStore";
import vertexShader from "./vertex.glsl";
import fragmentShader from "./fragment.glsl";

function Triangle(props) {
  const { getNoiseSize, getUTime } = useStore.getState();
  const plane = useRef();

  useFrame(() => {
    let noiseSize = getNoiseSize();
    const uTime = getUTime();
    plane.current.material.uniforms.noiseSize.value = noiseSize;
    plane.current.material.uniforms.uTime.value = uTime;
    plane.current.material.uniforms.uRes.value = {
      x: window.innerWidth,
      y: window.innerHeight,
    };
    plane.current.material.needsUpdate = true;
  });

  const uniforms = {
    uTime: { type: "f", value: 0.0 },
    uRes: { type: "vec2", value: { x: 500, y: 500 } },
    noiseSize: { type: "f", value: 1.0 },
    pallete: { type: "t", value: null },
    lineThickness: { type: "f", value: 0.004 },
    depth: { type: "f", value: 1.0 },
    opacity: { type: "f", value: 1.0 },
  };

  return (
    <mesh
      {...props}
      ref={plane}
      name="background"
      position={[0, 0, -100]}
      rotation={[0, 0, 0]}
    >
      <planeBufferGeometry
        args={[window.innerWidth, window.innerHeight, 100, 100]}
      />
      <shaderMaterial
        uniforms={uniforms}
        vertexShader={vertexShader}
        fragmentShader={fragmentShader}
        depthTest={true}
        transparent={true}
        map={true}
      />
    </mesh>
  );
}

export default Triangle;
