import React, { useRef, useEffect } from "react";
import { useFrame } from "@react-three/fiber";
import useStore from "@shared/hooks/useStore";
// import { TextureLoader, RepeatWrapping } from "three";
// import SimplexNoise from "@shared/SimplexNoise";
import vertexShader from "./vertex.glsl";
import fragmentShader from "./fragment.glsl";
import { TimelineMax } from "gsap";

function Lines(props) {
  const { getNoiseSize, getUTime, onBeat } = useStore.getState();
  const mesh = useRef();
  const introTween = useRef(null);
  const outroTween = useRef(null);

  useFrame(({ camera }) => {
    const { intro, outro, setIntro, setOutro } = useStore.getState();
    // console.log("intro / outro", intro, outro);

    if (intro && !introTween.current) {
      console.log("Performing intro animation");

      introTween.current = new TimelineMax({
        onComplete: () => {
          setIntro(false);
          outroTween.current = null;
        },
      });

      introTween.current.to(
        camera.position,
        0.5,
        {
          z: 420,
        },
        { ease: "power2.out" }
      );
    }

    if (outro && !outroTween.current) {
      console.log("Performing outro animation");

      outroTween.current = new TimelineMax({
        onComplete: () => {
          introTween.current = null;
          setOutro(false);
        },
      });

      outroTween.current.to(
        camera.position,
        0.5,
        {
          z: 20000,
        },
        { ease: "power2.in" }
      );
    }

    let noiseSize = getNoiseSize();
    const uTime = getUTime();
    mesh.current.material.uniforms.noiseSize.value = noiseSize;
    mesh.current.material.uniforms.uTime.value = uTime;
    mesh.current.material.uniforms.uRes.value = {
      x: window.innerWidth,
      y: window.innerHeight,
    };
    mesh.current.material.needsUpdate = true;
    // console.log(mesh.current.material.uniforms.depth);
  });

  const uniforms = {
    uTime: { type: "f", value: 0.0 },
    uRes: { type: "vec2", value: { x: 500, y: 500 } },
    noiseSize: { type: "f", value: 1.0 },
    pallete: { type: "t", value: null },
    lineThickness: { type: "f", value: 0.004 },
    depth: { type: "f", value: 1.0 },
  };

  useEffect(() => {
    let tween;
    const subscription = onBeat.subscribe((beatCount) => {
      // if (mesh.current) {
      //   mesh.current.material.uniforms.depth.value = 2.0;
      //   tween = TweenMax.to(mesh.current.material.uniforms.depth, 0.2, {
      //     value: 1.0,
      //     // delay: 0.2,
      //     easing: "power2.out",
      //   });
      // }
    });
    return () => {
      subscription.unsubscribe();
      if (tween) {
        tween.kill();
      }
    };
  });

  return (
    <mesh {...props} ref={mesh} name="background" position={[0, 0, -100]}>
      <planeBufferGeometry
        args={[window.innerWidth, window.innerHeight, 100, 100]}
      />
      <shaderMaterial
        uniforms={uniforms}
        vertexShader={vertexShader}
        fragmentShader={fragmentShader}
        depthTest={true}
        transparent={true}
        // wireframe={true}
        map={true}
      />
    </mesh>
  );
}

export default Lines;
