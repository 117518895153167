import React, { useRef, useEffect, useState, useCallback } from "react";
import _findIndex from "lodash/findIndex";
import "./Experiments.scss";
import Waves from "./Views/Waves";
import Cubes from "./Views/Cubes";
import Lines from "./Views/Lines";
import Triangle from "./Views/Triangle";
import Tattoo from "./Views/Tattoo";
import PolarEqual from "./Views/PolarEqual";
import { useHistory, useLocation } from "react-router-dom";
import FiberCanvas from "@shared/FiberCanvas";
// import View from "./View";
import useStore from "@shared/hooks/useStore";
import PageLoader from "@components/PageLoader";
import Controls from "./Controls";
// import { TweenMax } from "gsap";
// import { createPortal } from "@react-three/fiber";

function Experiments() {
  console.log("Experiments :: useStore", useStore.getState());

  // Hooks
  const { ready, loadSong } = useStore.getState();
  const location = useLocation();
  const history = useHistory();

  // State
  const [loaded, setLoaded] = useState(ready);

  // Refs
  const pageRef = useRef();
  const activeIndex = useRef();
  const nextIndex = useRef(0);
  // const pathName = useRef("/experiments/waves");
  const componentMap = useRef([
    { slug: "tattoo", Component: Tattoo },
    { slug: "waves", Component: Waves },
    { slug: "cubes", Component: Cubes },
    { slug: "lines", Component: Lines },
    { slug: "triangle", Component: Triangle },
    // { slug: "circles", Component: Circles },
    { slug: "polar-equal", Component: PolarEqual },
  ]);

  const handleIndexChange = (ai) => {
    // setOutro(true);
    nextIndex.current = ai;
    activeIndex.current = nextIndex.current;
    const ex = getByActiveIndex();
    history.push("/experiments/" + ex.slug);
  };

  const handlePrev = () => {
    let ai = activeIndex.current;
    ai--;
    if (ai < 0) {
      ai = componentMap.current.length - 1;
    }
    handleIndexChange(ai);
  };

  const handleNext = () => {
    let ai = activeIndex.current;
    ai++;
    if (ai >= componentMap.current.length) {
      ai = 0;
    }
    handleIndexChange(ai);
  };

  const getByActiveIndex = useCallback(() => {
    return componentMap.current[activeIndex.current];
  }, [componentMap]);

  const getRouteExperience = () => {
    console.log(activeIndex.current);
    let ai = _findIndex(componentMap.current, function (ex) {
      return "/experiments/" + ex.slug === location.pathname;
    });

    // endless loop
    if (ai === -1) {
      ai = 0;
    }

    activeIndex.current = ai;

    return componentMap.current[activeIndex.current];
  };

  useEffect(() => {
    // console.log("loadSong", loadSong);
    if (typeof loadSong !== "undefined") {
      loadSong({ songId: 3, autoplay: true });
    }
    // return () => {
    //   console.log("destroy");
    //   if (typeof destroy !== "undefined") {
    //     destroy();
    //   }
    // };
  }, [loadSong]);

  useEffect(() => {
    const readyUnsub = useStore.subscribe(
      (ready) => {
        if (loaded !== ready) {
          setLoaded(ready);
        }
      },
      (state) => {
        return state.ready;
      }
    );

    return () => {
      readyUnsub();
    };
  }, [loaded, getByActiveIndex, history]);

  const Component = getRouteExperience().Component;

  return (
    <div className="Page Experiments">
      <div
        className="Page-Content Experiments-Content"
        style={{ height: window.innerHeight }}
        ref={pageRef}
      >
        {loaded ? (
          <>
            <FiberCanvas id={`Fiber-Canvas`}>
              <Component />
            </FiberCanvas>
            <Controls prevExperience={handlePrev} nextExperience={handleNext} />
          </>
        ) : (
          <PageLoader />
        )}
      </div>
    </div>
  );
}

export default Experiments;
