import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import * as Tone from "tone";
// import { AnimatePresence } from "framer-motion";

// hooks
import useMusic from "@shared/hooks/useMusic";
import usePiano from "@components/Piano/hooks";

// Components
import BurgerMenu from "@components/BurgerMenu";
import Home from "../Pages/Home";
import About from "../Pages/About";
import Projects from "../Pages/Projects";
import Contact from "../Pages/Contact";
// import Magic from "../Pages/Magic";
// import Visual2 from "../Pages/Visual2";
// import Waves from "../Pages/Waves";
import Experiments from "../Pages/Experiments";

// Assets
import logoSvg from "@assets/images/kt-logo-white.svg";
import "./App.scss";

import * as THREE from "three";
window.THREE = THREE;

function App() {
  // init music hook
  usePiano();
  useMusic();

  useEffect(() => {
    const startAudioContext = async () => {
      console.log("startAudioContext");
      document
        .querySelector("body")
        .removeEventListener("click", startAudioContext);
      return await Tone.start();
    };

    document.querySelector("body").addEventListener("click", startAudioContext);
    return () => {
      document
        .querySelector("body")
        .removeEventListener("click", startAudioContext);
    };
  }, []);

  return (
    <Router>
      <BurgerMenu className="BurgerMenu" />
      <div className="App">
        <Link to="/">
          <img className="Logo" src={logoSvg} alt="Logo" />
        </Link>
        <main className="App-Main">
          {/* <AnimatePresence> */}
          <Switch>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/art">
              <Projects />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            {/* <Route path="/magic">
                  <Magic />
                </Route> */}
            {/* <Route path="/nyan">
                  <Visual2 />
                </Route> */}
            {/* <Route path="/waves">
                  <Waves />
                </Route> */}
            <Route exact={true} path="/">
              <Home />
            </Route>
            <Route path="/experiments/:slug?" children={<Experiments />} />
          </Switch>
          {/* </AnimatePresence> */}
        </main>
      </div>
    </Router>
  );
}

export default App;
