import React, { useRef } from "react";
import BackgroundShaderMaterial from "../shaders/BackgroundShaderMaterial";
import { useFrame, useLoader } from "@react-three/fiber";
import useStore from "@shared/hooks/useStore";
import { TextureLoader, RepeatWrapping } from "three";

function Background(props) {
  const { getNoiseSize, getUTime } = useStore.getState();
  const mesh = useRef();

  const texture = useLoader(
    TextureLoader,
    "https://res.cloudinary.com/dptp8ydoe/image/upload/a_90/v1614659029/red-background-black-clouds_jgwa7c.png"
  );
  texture.wrapS = RepeatWrapping;
  texture.wrapT = RepeatWrapping;
  texture.repeat.set(10, 10);

  const uniforms = BackgroundShaderMaterial.uniforms;
  uniforms.pallete.value = texture;

  useFrame(() => {
    const noiseSize = getNoiseSize();
    const uTime = getUTime();
    mesh.current.material.uniforms.noiseSize.value = noiseSize;
    mesh.current.material.uniforms.uTime.value = uTime;
  });

  return (
    <mesh {...props} ref={mesh} name="background">
      <planeBufferGeometry args={[5000, 5000, 100, 100]} />
      <shaderMaterial
        uniforms={uniforms}
        vertexShader={BackgroundShaderMaterial.vertexShader}
        fragmentShader={BackgroundShaderMaterial.fragmentShader}
        depthTest={true}
        transparent={true}
      />
    </mesh>
  );
}

export default Background;
