import React from "react";
import CirclesMesh from "./fiber/Circles";

function PolarEqual() {
  return (
    <>
      <CirclesMesh />
    </>
  );
}
export default PolarEqual;
