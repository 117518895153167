import * as Tone from "tone";
import useStore from "@shared/hooks/useStore";
// import { updateAudioProps } from "@components/Piano";

/**
 * usePiano
 *
 * Interface for Piano Synth => PianoContextProvider
 *
 * @return {*} */

function usePiano() {
  console.log("usePiano");

  const { setInitialState } = useStore.getState();

  // const fft = new Tone.Analyser("fft", 1024);
  const waveform = new Tone.Analyser("waveform", 1024);
  const synth = new Tone.Synth();
  // synth.chain(fft, waveform, Tone.Destination);
  synth.chain(waveform, Tone.Destination);
  // synth.toDestination();

  setInitialState("waveform", waveform);
  setInitialState("synth", synth);

  return { synth, waveform };
}

export default usePiano;
