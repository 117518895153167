import React, { useRef } from "react";
import { useFrame } from "@react-three/fiber";
import useStore from "@shared/hooks/useStore";
// import { TextureLoader, RepeatWrapping } from "three";
// import { TweenMax } from "gsap";

import vertexShader from "./vertex.glsl";
import fragmentShader from "./fragment.glsl";

function Cube(props) {
  const { getNoiseSize, getUTime } = useStore.getState();
  const mesh = useRef();

  useFrame(() => {
    const noiseSize = getNoiseSize();
    const uTime = getUTime();
    mesh.current.material.uniforms.noiseSize.value = noiseSize;
    mesh.current.material.uniforms.uTime.value = uTime;
    mesh.current.material.uniforms.uRes.value = {
      x: window.innerWidth,
      y: window.innerHeight,
    };
    mesh.current.material.needsUpdate = true;

    // mesh.current.rotation.x += 0.008 + noiseSize * 5.0;
    // mesh.current.rotation.y += 0.008 + noiseSize * 5.0;
    // mesh.current.rotation.z += 0.008 + noiseSize * 5.0;
  });

  // const texture = useLoader(
  //   TextureLoader,
  //   "https://res.cloudinary.com/dptp8ydoe/image/upload/a_90/v1614659029/red-background-black-clouds_jgwa7c.png"
  // );
  // texture.wrapS = RepeatWrapping;
  // texture.wrapT = RepeatWrapping;
  // texture.repeat.set(100, 100);

  const uniforms = {
    uTime: { type: "f", value: 0.0 },
    uRes: { type: "vec2", value: { x: 500, y: 500 } },
    noiseSize: { type: "f", value: 1.0 },
    pallete: { type: "t", value: null },
  };

  // uniforms.pallete.value = texture;

  // useEffect(() => {
  //   const subscription = onBeat.subscribe((beatCount) => {
  //     mesh.current.rotation.x += 0.1;

  //     if (beatCount % 4) {
  //       TweenMax.to(mesh.current.rotation, 0.5, {
  //         x: mesh.current.rotation.x + 0.1,
  //       });
  //       TweenMax.to(mesh.current.rotation, 0.5, {
  //         y: mesh.current.rotation.y + 0.1,
  //       });
  //       TweenMax.to(mesh.current.rotation, 0.5, {
  //         z: mesh.current.rotation.z + 0.1,
  //       });
  //     }
  //   });

  //   return () => subscription.unsubscribe();
  // });

  return (
    <mesh
      {...props}
      ref={mesh}
      name="pineapple"
      position={[0, 0, -1000]}
      rotation={[0, 0, 0]}
    >
      <planeBufferGeometry args={[2000, 2000, 100, 100]} />
      <shaderMaterial
        uniforms={uniforms}
        vertexShader={vertexShader}
        fragmentShader={fragmentShader}
        depthTest={true}
        alphaTest={true}
        transparent={true}
        // wireframe={true}
        map={true}
      />
    </mesh>
  );
}

export default Cube;
