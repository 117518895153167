const baseUrl = "/assets/audio/";

const songs = [
  {
    title: 'Tattoo Sizzle',
    bpm: 96,
    artist: { title: 'Tattoo Projects' },
    url: 'https://player.vimeo.com/external/173772067.hd.mp4?s=eba6b8de91eb26827b3efaa83c4430959bb7e1bd&profile_id=175',
  },
  {
    title: "Windowdipper",
    bpm: 104,
    artist: { title: "Jib Kidder" },
    url: baseUrl + "windowdipper.m4a",
  },
  {
    title: "Landslide (remix)",
    bpm: 104,
    artist: { title: "Fleetwood Mac" },
    url: baseUrl + "landslide-remix.m4a",
  },
  {
    title: "Girls Just Want to Have Fun",
    bpm: 104,
    artist: { title: "STRFKR" },
    url: baseUrl + "girls-just-wanna.m4a",
  },
  {
    title: "James Brown",
    bpm: 104,
    artist: { title: "SNBRN" },
    url: baseUrl + "james-brown.m4a",
  },
  {
    title: "Higher Love",
    bpm: 104,
    artist: { title: "Kygo feat. Whitney Houston" },
    url: baseUrl + "higher-love.mp3",
  },
  {
    title: "Express Yourself",
    bpm: 96,
    artist: { title: "N.W.A." },
    url: baseUrl + "express-yourself.m4a",
  },
  // {
  //   title: "La Di Da Di.mp3",
  //   artist: { title: "Slick Rick" },
  //   url: baseUrl + "la-di-da-di.mp3",
  // },
  {
    title: "Into You",
    bpm: 91,
    artist: { title: "Fabulus" },
    url: baseUrl + "into-you.m4a",
  },
  {
    title: "Mr. Big Stuff",
    bpm: 93,
    artist: { title: "Jean Knight" },
    url: baseUrl + "mr-big-stuff.m4a",
  },
  {
    title: "Pretty Girls",
    bpm: 89,
    artist: { title: "Wale" },
    url: baseUrl + "pretty-girls.m4a",
  },
  {
    title: "MY PYT",
    bpm: 96,
    artist: { title: "Wale" },
    url: baseUrl + "my-pyt.m4a",
  },
  {
    title: "Nyan Cat",
    bpm: 142,
    artist: { title: "Kitty" },
    url: baseUrl + "nyan.m4a",
  },
];

songs.forEach((s, index) => {
  songs[index].index = index;
});

const getSongByID = (id) => songs[id];
const getSongData = () => songs;
export default getSongData;
export { getSongByID };
