import React, { useRef, useEffect } from "react";
import { useFrame } from "@react-three/fiber";
import useStore from "@shared/hooks/useStore";
// import { TextureLoader, RepeatWrapping } from "three";
// import SimplexNoise from "@shared/SimplexNoise";
import vertexShader from "./vertex.glsl";
import fragmentShader from "./fragment.glsl";
// import { TweenMax } from "gsap";

function Circles(props) {
  const { getNoiseSize, getUTime, onBeat, fft } = useStore.getState();
  const mesh = useRef();
  useFrame(() => {
    let noiseSize = getNoiseSize();
    const uTime = getUTime();
    mesh.current.material.uniforms.noiseSize.value = noiseSize;
    mesh.current.material.uniforms.uTime.value = uTime;
    mesh.current.material.uniforms.uRes.value = {
      x: window.innerWidth,
      y: window.innerHeight,
    };
    mesh.current.material.needsUpdate = true;

    mesh.current.material.uniforms.freqs.value = fft.getValue();

    // console.log(mesh.current.material.uniforms.depth);
  });

  const uniforms = {
    uTime: { type: "f", value: 0.0 },
    uRes: { type: "vec2", value: { x: 500, y: 500 } },
    noiseSize: { type: "f", value: 1.0 },
    pallete: { type: "t", value: null },
    lineThickness: { type: "f", value: 0.004 },
    depth: { type: "f", value: 1.0 },
    freqs: {
      type: "fv",
      value: new Array(32).fill(1.0),
    },
  };

  useEffect(() => {
    const subscription = onBeat.subscribe((beatCount) => {
      // console.log(beatCount);
      // TweenMax.to(iTime.current, 0.1, {
      //   value: iTime.current + 1000.0,
      //   easing: 'power2.out'
      // });
      // if(beatCount % 2) {
      // mesh.current.material.uniforms.depth.value = 2.0;
      //   // TweenMax.to(mesh.current.material.uniforms.depth, 0.05, {
      //   //   value: 3.0,
      //   //   easing: 'power2.in'
      //   // });
      //   TweenMax.to(mesh.current.material.uniforms.depth, 0.2, {
      //     value: 1.0,
      //     // delay: 0.2,
      //     easing: 'power2.out'
      //   });
      // }
    });
    return () => subscription.unsubscribe();
  });

  return (
    <mesh {...props} ref={mesh} name="background" position={[0, 0, -100]}>
      <planeBufferGeometry
        args={[window.innerWidth, window.innerHeight, 100, 100]}
      />
      <shaderMaterial
        uniforms={uniforms}
        vertexShader={vertexShader}
        fragmentShader={fragmentShader}
        depthTest={true}
        transparent={true}
        // wireframe={true}
        map={true}
      />
    </mesh>
  );
}

export default Circles;
