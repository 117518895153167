import React from "react";
import { Canvas } from "@react-three/fiber";

function FiberCanvas(props) {
  return (
    <Canvas
      {...props}
      onCreated={(state) => {
        window.scene = state.scene;
        const __THREE_DEVTOOLS__ = window["__THREE_DEVTOOLS__"];

        if (typeof __THREE_DEVTOOLS__ !== "undefined") {
          __THREE_DEVTOOLS__.dispatchEvent(
            new CustomEvent("observe", { detail: state.scene })
          );
          __THREE_DEVTOOLS__.dispatchEvent(
            new CustomEvent("observe", { detail: state.gl })
          );
        }
      }}
      mode="concurrent"
      camera={{ fov: 70, position: [0, 0, 420], near: 0.1, far: 15000 }}
    >
      <ambientLight />
      {props.children}
    </Canvas>
  );
}

export default FiberCanvas;
