// import React, { useRef, useEffect, Suspense } from "react";
import React, { Suspense } from "react";
import { useFrame } from "@react-three/fiber";
// import { TimelineMax } from "gsap";
// import useStore from "@shared/hooks/useStore";
import Background from "./fiber/Background";
import Sphere from "./fiber/Sphere";

function Waves() {
  // const introTween = useRef(null);
  // const outroTween = useRef(null);

  // const { setIntro, setOutro } = useStore.getState();
  // useEffect(() => {
  //   setOutro(true);
  //   return () => {
  //     if (introTween.current) {
  //       introTween.current.kill();
  //     }
  //     if (outroTween.current) {
  //       outroTween.current.kill();
  //     }
  //   };
  // }, []);

  useFrame(({ camera }) => {
    // const { intro, outro, setIntro, setOutro } = useStore.getState();
    // if (intro && !introTween.current) {
    //   console.log("Performing intro animation");
    //   setIntro(false);
    //   outroTween.current = null;
    //   //   introTween.current = new TimelineMax({
    //   //     onComplete: () => {
    //   //       setIntro(false);
    //   //       outroTween.current = null;
    //   //     },
    //   //   });
    //   //   introTween.current.to(
    //   //     camera.position,
    //   //     0.5,
    //   //     {
    //   //       z: 420,
    //   //     },
    //   //     { ease: "power2.out" }
    //   //   );
    // }
    // if (outro && !outroTween.current) {
    //   console.log("Performing outro animation");
    //   introTween.current = null;
    //   setOutro(false);
    //   // outroTween.current = new TimelineMax({
    //   //   onComplete: () => {
    //   //     introTween.current = null;
    //   //     setOutro(false);
    //   //   },
    //   // });
    //   // outroTween.current.to(
    //   //   camera.position,
    //   //   0.5,
    //   //   {
    //   //     z: 20000,
    //   //   },
    //   //   { ease: "power2.in" }
    //   // );
    // }
  });

  return (
    <>
      <Suspense fallback={null}>
        <Background position={[0, 0, -1000]} rotation={[0, 0, Math.PI / 2]} />
      </Suspense>
      <Suspense fallback={null}>
        <Sphere position={[0, 0, -1000]} />
      </Suspense>
    </>
  );
}
export default Waves;
