import React from "react";
import "./Controls.scss";
import useStore from "@shared/hooks/useStore";
import { FaPlay, FaPause, FaArrowRight, FaArrowLeft } from "react-icons/fa";

function Controls({ prevExperience, nextExperience }) {
  const { play, pause, songs, loadSong, song } = useStore.getState();
  const playing = useStore((state) => state.playing);

  const PlayPauseButton = ({ playing }) => {
    return playing ? (
      <button className="PlayPause Pause" onClick={pause}>
        <FaPause />
      </button>
    ) : (
      <button className="PlayPause Play" onClick={play}>
        <FaPlay />
      </button>
    );
  };

  const handleloadSong = (e) => {
    loadSong({ songId: e.target.value, autoplay: true });
  };

  const SongSelector = () => {
    return (
      <select
        className="SongSelector"
        defaultValue={song.index}
        onChange={handleloadSong}
      >
        {songs.map((s, si) => {
          return (
            <option key={s.title} value={si}>
              {s.title} - {s.artist.title}
            </option>
          );
        })}
      </select>
    );
  };

  return (
    <div className="Controls">
      <div id="gui-container"></div>
      <SongSelector />
      <button className="PrevNext Prev" onClick={prevExperience}>
        <FaArrowLeft />
      </button>
      <button className="PrevNext Next" onClick={nextExperience}>
        <FaArrowRight />
      </button>
      <PlayPauseButton playing={playing} />
    </div>
  );
}

export default Controls;
