import React, { useState } from "react";
import { TweenMax } from "gsap";
import Piano from "@components/Piano";
import "./Home.scss";
import FiberCanvas from "@shared/FiberCanvas";
import Sphere from "./fiber/Sphere";

function Home() {
  const doSomething = () => {
    TweenMax.to(".Piano-Container", {
      opacity: 1,
      bottom: "5%",
      duration: 0.5,
      // delay: 1,
    }); //wait 2 seconds
    // window.dispatchEvent(new CustomEvent("DO_SOMETHING"));
    setPianoActive(true);
  };

  const [pianoActive, setPianoActive] = useState(false);

  return (
    <div className="Page Home">
      <div
        className="Page-Content Home-Content"
        style={{ height: window.innerHeight }}
      >
        {pianoActive ? (
          <FiberCanvas id={`Fiber-Canvas`}>
            <Sphere />
          </FiberCanvas>
        ) : (
          <button className="btn-circle" onClick={doSomething}>
            Touch me
          </button>
        )}
        <Piano />
      </div>
    </div>
  );
}

export default Home;
