import React, { useRef } from "react";
import { useFrame, useLoader } from "@react-three/fiber";
import useStore from "@shared/hooks/useStore";
import { TextureLoader, RepeatWrapping } from "three";

import vertexShader from "./vertex.glsl";
import fragmentShader from "./fragment.glsl";

function Sphere(props) {
  const { getNoiseSize, getUTime } = useStore.getState();
  const mesh = useRef();

  useFrame(() => {
    const noiseSize = getNoiseSize();
    const uTime = getUTime();
    mesh.current.material.uniforms.noiseSize.value = noiseSize;
    mesh.current.material.uniforms.uTime.value = uTime;
    mesh.current.material.needsUpdate = true;
  });

  const texture = useLoader(
    TextureLoader,
    "https://res.cloudinary.com/dptp8ydoe/image/upload/v1614656273/lightning-black_lcdi5z.png"
  );
  texture.wrapS = RepeatWrapping;
  texture.wrapT = RepeatWrapping;
  texture.repeat.set(1, 1);

  const uniforms = {
    uTime: { type: "f", value: 0.0 },
    uRes: { type: "vec2", value: { x: 500, y: 500 } },
    uMouse: { type: "vec3", value: { x: 0, y: 0, z: 0 } },
    noiseSize: { type: "f", value: 1.0 },
    raycastUv: { type: "vec2", value: { x: 0, y: 0 } },
    depth: { type: "f", value: 10.0 },
    pallete: { type: "t", value: null },
    freqs: {
      type: "fv",
      value: new Array(32).fill(1.0),
    },
    scaleX: { type: "f", value: 1.0 },
    scaleY: { type: "f", value: 1.0 },
    scaleZ: { type: "f", value: 1.0 },
  };

  uniforms.pallete.value = texture;

  return (
    <mesh {...props} ref={mesh} name="background">
      <sphereBufferGeometry args={[500, 100, 100]} />
      <shaderMaterial
        uniforms={uniforms}
        vertexShader={vertexShader}
        fragmentShader={fragmentShader}
        depthTest={true}
        transparent={true}
        // wireframe={true}
      />
    </mesh>
  );
}

export default Sphere;
