import React from "react";
import TriangleMesh from "./fiber/Triangle";

function Triangle() {
  return (
    <>
      <TriangleMesh />
    </>
  );
}
export default Triangle;
