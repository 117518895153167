import { useRef, useEffect, useCallback } from "react";
import * as Tone from "tone";
import getSongData from "./getSongData";
import { updateAudioProps } from "@components/Piano";
import { Subject } from "rxjs";
import useStore from "./useStore";

const songs = getSongData();

/**
 * useMusic
 *
 * @return {*} */

function useMusic() {
  console.log("useMusic");

  const { setInitialState, setPlaying, setReady, setSong, ready } =
    useStore.getState();

  // React Refs
  const player = useRef();
  const waveform = useRef(new Tone.Analyser("waveform", 32));
  const onBeatRef = useRef(new Subject());
  const fftRef = useRef(new Tone.FFT(32));
  const beatCount = useRef(0);
  const noiseSize = useRef(0);
  const uTime = useRef(1000);
  let requestId = useRef();

  waveform.current.normalRange = true;

  const start = useCallback(() => {
    Tone.Transport.pause();
    player.current.sync().start();
    Tone.Transport.start("+0");
    setPlaying(true);
  }, [setPlaying]);

  const play = useCallback(() => {
    Tone.Transport.start();
    setPlaying(true);
  }, [setPlaying]);

  const pause = useCallback(() => {
    Tone.Transport.pause();
    setPlaying(false);
  }, [setPlaying]);

  const destroy = useCallback(() => {
    player.current.dispose();
  }, []);

  const getNoiseSize = () => {
    return noiseSize.current;
  };

  const getUTime = () => {
    return uTime.current;
  };

  const loadSong = useCallback(
    ({ songId, autoplay = false }) => {
      return new Promise((resolve, reject) => {
        // console.log("loadSong", songId);
        const song = setSong(songId);

        console.log({ songId, autoplay, song });

        Tone.Transport.bpm.value = song.bpm;

        Tone.Transport.scheduleRepeat((time) => {
          beatCount.current++;
          onBeatRef.current.next(beatCount.current);
        }, "2n");

        Tone.Transport.seconds = 0;

        fftRef.current.normalRange = true;

        new Tone.Buffer(
          song.url,
          (buffer) => {
            const buff = buffer.get();

            if (player.current) {
              player.current.dispose();
            }

            player.current = new Tone.Player(buff);
            player.current.chain(
              fftRef.current,
              waveform.current,
              Tone.Destination
            );

            if (!ready) {
              start();
              setReady(true);
            }

            if (autoplay) {
              console.log("autoplay");
              play();
            }

            resolve();
          },
          (err) => {
            console.log("Buffer Error");
            console.log(err);
            reject(err);
          }
        );
      });
    },
    [ready, start, setReady, play, setSong]
  );

  useEffect(() => {
    // properties
    setInitialState("songs", songs);
    setInitialState("ready", false);
    setInitialState("playing", false);
    setInitialState("song", songs[5]);
    setInitialState("intro", true);
    setInitialState("outro", false);
    setInitialState("player", player);
    setInitialState("onBeat", onBeatRef.current);
    setInitialState("fft", fftRef.current);
  }, [onBeatRef, fftRef, setInitialState]);

  useEffect(() => {
    // functions
    setInitialState("loadSong", loadSong);
    setInitialState("play", play);
    setInitialState("start", start);
    setInitialState("pause", pause);
    setInitialState("destroy", destroy);
    setInitialState("getNoiseSize", getNoiseSize);
    setInitialState("getUTime", getUTime);
  });

  useEffect(() => {
    return () => {
      if (player.current) {
        player.current.dispose();
      }
    };
  }, []);

  useEffect(() => {
    const updateNoiseSize = () => {
      noiseSize.current = updateAudioProps(fftRef.current);
    };

    const updateNoiseTime = () => {
      uTime.current += 0.01;
    };

    const animate = () => {
      // const { playing } = useStore.getState();
      requestId.current = requestAnimationFrame(animate);
      updateNoiseSize();
      // if (playing) {
      updateNoiseTime();
      // }
    };
    animate();

    return () => {
      cancelAnimationFrame(requestId.current);
    };
  });

  return useStore;
}

export default useMusic;
