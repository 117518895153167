import React from "react";
// import "./PageLoader.scss";
import Loader from "react-loader-spinner";

function PageLoader() {
  return (
    <Loader
      type="TailSpin"
      color="#fff"
      height={100}
      width={100}
      timeout={3000}
    />
  );
}

export default PageLoader;
