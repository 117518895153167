const data = [
  {
    number: "01",
    title: "Love #1",
    img: "https://res.cloudinary.com/dptp8ydoe/image/upload/v1613751046/love1_mtdzzw.jpg",
  },
  {
    number: "02",
    title: "Soul",
    img: "https://res.cloudinary.com/dptp8ydoe/image/upload/v1613750735/soul_mnnw8w.jpg",
  },
  {
    number: "03",
    title: "Butz",
    img: "https://res.cloudinary.com/dptp8ydoe/image/upload/v1613750955/butz_aofgvt.jpg",
  },
  {
    number: "04",
    title: "Love #2",
    img: "https://res.cloudinary.com/dptp8ydoe/image/upload/v1613751114/love2_u7pk3z.jpg",
  },
  {
    number: "05",
    title: "TrumpMore",
    img: "https://res.cloudinary.com/dptp8ydoe/image/upload/v1613750839/trumpmore_esxudw.jpg",
  },
  {
    number: "06",
    title: "LGBT #1",
    img: "https://res.cloudinary.com/dptp8ydoe/image/upload/v1613750339/lgbt1_mw4ara.jpg",
  },
  {
    number: "07",
    title: "Happy",
    img: "https://res.cloudinary.com/dptp8ydoe/image/upload/v1613750234/happy_btlpzw.jpg",
  },
  {
    number: "08",
    title: "Ruth",
    img: "https://res.cloudinary.com/dptp8ydoe/image/upload/v1613750077/ruth_x8fsvk.jpg",
  },
];

export default data;
