import create from "zustand";
import { getSongByID } from "@shared/hooks/getSongData";

const useStore = create((set, get, api) => ({
  getSong: () => get()["song"],
  getPlaying: () => get()["playing"],
  setReady: (v) => set(() => ({ ready: v })),
  setPlaying: (v) => set(() => ({ playing: v })),
  setSong: (id) => {
    const song = getSongByID(id);
    set(() => ({ song: song }));
    return song;
  },
  setIntro: (v) => set(() => ({ intro: v })),
  setOutro: (v) => set(() => ({ outro: v })),
  setInitialState: (type, initialState) => {
    set((state) => {
      return {
        ...state,
        [type]: initialState,
      };
    });
  },
}));

export default useStore;
