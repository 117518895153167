import React from "react";
import LinesMesh from "./fiber/Lines";

function Lines() {
  return (
    <>
      <LinesMesh />
    </>
  );
}
export default Lines;
