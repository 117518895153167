import React, { useRef } from "react";
import { useFrame } from "@react-three/fiber";
import useStore from "@shared/hooks/useStore";
// import useClock from "@shared/hooks/useClock";
import { updateAudioProps } from "@components/Piano";
import vertexShader from "./mesh.vert";
import fragmentShader from "./mesh.frag";

function Sphere(props) {
  const { waveform } = useStore.getState();
  const mesh = useRef();
  const width = window.innerWidth / 3;

  useFrame(({ clock }) => {
    let noiseSize = updateAudioProps(waveform);
    mesh.current.material.uniforms.noiseSize.value = noiseSize;
    // mesh.current.material.uniforms.uTime.value = clock.getFixedTimed();
    // mesh.current.material.uniforms.uTime.value =
    //   (1000 + clock.elapsedTime / 1000) / 1000 + 1000;
    mesh.current.material.uniforms.uTime.value = 10000 + clock.elapsedTime;
    // console.log(mesh.current.material.uniforms.uTime.value);
    mesh.current.material.uniforms.uRes.value = {
      x: window.innerWidth,
      y: window.innerHeight,
    };
    mesh.current.material.needsUpdate = true;
  });

  const uniforms = {
    uTime: { type: "f", value: 0.0 },
    uRes: { type: "vec2", value: { x: 500, y: 500 } },
    depth: { type: "f", value: 100.0 },
    timeDepth: { type: "f", value: 0.0000012 },
    timeSpeed: { type: "f", value: 0.25 }, // the speed at which the perlin noise (pn1) moves / rotates
    noiseDepth: { type: "f", value: 0.002 },
    noiseSize: { type: "f", value: 0.0 }, // fft provided volume
  };

  return (
    <mesh
      {...props}
      ref={mesh}
      name="sphere"
      position={[0, 0, -width]}
      // rotation={[Math.PI / 4, -Math.PI / 4, Math.PI / 4]}
    >
      <sphereBufferGeometry args={[width, 500, 500]} />
      <rawShaderMaterial
        uniforms={uniforms}
        vertexShader={vertexShader}
        fragmentShader={fragmentShader}
        depthTest={true}
        transparent={true}
        // wireframe={true}
        map={true}
      />
    </mesh>
  );
}

export default Sphere;
