// import React, { useRef, useEffect, Suspense } from "react";
import React, { Suspense } from "react";
import { useFrame } from "@react-three/fiber";
// import useStore from "@shared/hooks/useStore";
// import Background from "./fiber/Background";
import VideoPlane from "./fiber/VideoPlane";

function Tattoo() {

  useFrame(({ camera }) => {
   
  });

  return (
    <>
      <Suspense fallback={null}>
        {/* <Background position={[0, 0, -1000]} rotation={[0, 0, Math.PI / 2]} /> */}
      </Suspense>
      <Suspense fallback={null}>
        <VideoPlane
          // videoUrl={`http://localhost:3000/assets/3335840271.mp4`}
          videoUrl='https://player.vimeo.com/external/173772067.hd.mp4?s=eba6b8de91eb26827b3efaa83c4430959bb7e1bd&profile_id=175'
          position={[0, 0, 0]}
        />
      </Suspense>
    </>
  );
}
export default Tattoo;
